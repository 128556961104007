import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useSnackbar } from '../../hooks/useSnackbar';
import { API } from '../../api/api_setup';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import Navbar from '../../components/Navbar';
import { Download, Home, Visibility } from '@mui/icons-material';
import DataDisplay from '../../components/DataDisplay';
import axios from 'axios';

const AllFiles = () => {
    const { clusterName } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [fileLoading, setFileLoading] = useState(false)
    const { user, } = useAuth()
    const { showSnackbar } = useSnackbar();
    const [files, setFiles] = useState([])
    const [fileContent, setFileContent] = useState('')

    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    }
    const viewFile = (cellValue) => {
        const viewLink = cellValue.value
        setFileLoading(true)
        setFileContent('')
        axios.get(viewLink, axiosConfig)
            .then(response => {
                if (response.data.data) {
                    setFileContent(response.data.data['raw'])
                }
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    showSnackbar(err.response.data.message)
                } else if (err.message) {
                    showSnackbar(err.message)
                }
            })
            .finally(() => {
                setFileLoading(false)
            })

    }
    const downloadFile = (cellValue) => {
        const downloadLink = cellValue.value
        const timestamp = cellValue.row.timestamp
        const filename = cellValue.row.fileName
        axios.get(downloadLink, { ...axiosConfig, responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${clusterName}-${timestamp}-${filename}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    showSnackbar(err.response.data.message)
                } else if (err.message) {
                    showSnackbar(err.message)
                }
            })
    }

    useEffect(() => {
        API.get(`/files/${clusterName}`, axiosConfig)
            .then(response => {
                if (response.data.data) {
                    setFiles(response.data.data)
                }
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    showSnackbar(err.response.data.message)
                } else if (err.message) {
                    showSnackbar(err.message)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clusterName])

    const fileColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50
        },
        {
            field: 'fileName',
            headerName: 'File Name',
            width: 250
        },
        {
            field: 'timestamp',
            headerName: 'Timestamp',
            width: 250
        },
        {
            field: 'viewLink',
            headerName: 'View',
            renderCell: (cellValue) => {
                return <IconButton onClick={() => viewFile(cellValue)}><Visibility /></IconButton>
            }
        },
        {
            field: 'downloadLink',
            headerName: 'Download',
            renderCell: (cellValue) => {
                return <IconButton onClick={() => downloadFile(cellValue)}><Download /></IconButton>
            }
        },
    ]

    return (
        <Grid container>
            <Grid item lg={12} md={12}>
                <Navbar leftWidgetList={[
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="home"
                        onClick={() => { navigate("/") }}
                        sx={{ marginLeft: '-10px' }}
                        key={0}
                    >
                        <Home sx={{ fontSize: 40 }} />
                    </IconButton>,
                    <Typography variant='h4' sx={{ marginLeft: '10px' }} key={1}>{clusterName}</Typography>
                ]}
                />
            </Grid>
            <Grid item lg={12} md={12} height={'40vh'}>
                <DataDisplay
                    rows={files}
                    columns={fileColumns}
                    loading={loading}
                    sx={{ margin: '10px' }}
                    density='compact'
                />
            </Grid>
            <Grid item lg={12} md={12} height={'70vh'} overflow={'scroll'} marginTop={'20px'}>
                {
                    fileLoading ? <Box sx={{ height: 40, mt: 40, marginLeft: '45%' }}>
                        <CircularProgress size={100} />
                    </Box> : null
                }
                <Typography variant='body1' fontSize={'14px'} padding={2}>
                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                        {fileContent}
                    </pre>
                </Typography>
            </Grid>
        </Grid>

    );
}

export default AllFiles;
