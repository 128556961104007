import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import { Home } from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';
import { API } from '../../api/api_setup';
import { useSnackbar } from '../../hooks/useSnackbar';
import ReactJson from 'react-json-view'; 

const DataFiles = () => {
    const { clusterName, tableName } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const { user, } = useAuth()
    const { showSnackbar } = useSnackbar();
    const [jsonData, setJsonData] = useState({})
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    }

    useEffect(() => {
        const query = `select * from ${tableName} 
        where cluster_name='${clusterName}'
        order by timestamp desc limit 1`
        API.post("/database_run_query", {
            query
        }, axiosConfig)
            .then(response => {
                if (response.data.data) {
                    setJsonData(JSON.parse(response.data.data[0].data))
                }
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    showSnackbar(err.response.data.message)
                } else if (err.message) {
                    showSnackbar(err.message)
                }
            })
            .finally(() => {
                setLoading(false)
            })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clusterName, tableName])
    
    return (
        <Grid container>
            <Grid item lg={12} md={12}>
                <Navbar leftWidgetList={[
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="home"
                        onClick={() => { navigate("/") }}
                        sx={{ marginLeft: '-10px' }}
                        key={0}
                    >
                        <Home sx={{ fontSize: 40 }} />
                    </IconButton>,
                    <Typography variant='h4' sx={{ marginLeft: '10px' }} key={1}>{clusterName}: {tableName}</Typography>
                ]}
                />
            </Grid>
            <Grid item lg={12} md={12} height={'90vh'} overflow={'scroll'} paddingTop={'20px'}>
                {
                    loading ? <Box sx={{ height: 40, mt: 40, marginLeft: '45%' }}>
                        <CircularProgress size={100} />
                    </Box> : null
                }
                {
                    <ReactJson src={jsonData}  collapsed={3} displayDataTypes={false} />                
                }
            </Grid>
        </Grid>
    );
}

export default DataFiles;
