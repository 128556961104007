import dayjs from "dayjs";

const countFormatterWithCommas = (count) => {
    if (isNaN(Number(count)))
      return "--"
    count = Math.round(count)
    return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

const indexFormatter = (index) => {
  return (index + 1)
}

const getPercentageSkippedOverTotal = (value, row) => {
    const numer = row.overallSkipped
    const denom = row.overallScanned
    if (denom === 0)
      return "--"
    const percent = ((numer * 100)/denom).toFixed(2)
    if (isNaN(percent))
      return "--"
    return `${percent}%`
}

const compareTo = (v1, v2) => {
  if (v1 < v2)
    return -1
  else if (v1 > v2)
    return 1
  else
    return 0
}

const commaValueComparator = (v1, v2) => {
    const regex = /[, %]/g
    v1 = String(v1)
    v2 = String(v2)
    v1 = Number(v1.replaceAll(regex, ""))
    v2 = Number(v2.replaceAll(regex, ""))
    if (isNaN(v1) && isNaN(v2))
        return 0
    if (isNaN(v2))
        return -1
    if (isNaN(v1))
        return 1
    return compareTo(v1,v2)
}

const multipurposeSortComparator = (sortDirection) => {
  const modifier = sortDirection === 'desc' ? -1 : 1;
  return (value1, value2, cellParams1, cellParams2) => {
    const regex = /[, %]/g
    value1 = Number(String(value1).replaceAll(regex, ""))
    value2 = Number(String(value2).replaceAll(regex, ""))
    if (isNaN(value1)) {
      return 1;
    }
    if (isNaN(value2)) {
      return -1;
    }
    return (
      modifier *
      compareTo(value1, value2)
    );
  };
}

const graphSlopeComparator = (sortDirection) => {
  const modifier = sortDirection === 'desc' ? -1 : 1;
  return (value1, value2, cellParams1, cellParams2) => {
    const row1 = cellParams1.api.getRow(cellParams1.id)
    const row2 = cellParams2.api.getRow(cellParams2.id)
    let countZeroes1 = 0, countZeroes2 = 0;
    let avgCounts1 = 0, avgCounts2 = 0;
    let n1 = 0, n2 = 0;
    for(const key in row1){
      if (!key.includes("CountDay"))
        continue;
      if (row1[key] === 0 || row1[key] === "NA")
        countZeroes1 +=1
      if (row2[key] === 0 || row2[key] === "NA")
        countZeroes2 +=1

      if(row1[key] !== "NA"){
        avgCounts1+=row1[key];
        n1+=1;
      }
      if(row2[key] !== "NA"){
        avgCounts2+=row2[key];
        n2+=1;
      }
    }
    avgCounts1 = avgCounts1/n1
    avgCounts2 = avgCounts2/n2
    const zeroCompare= compareTo(countZeroes1, countZeroes2)
    if (zeroCompare !== 0)
      return zeroCompare

    value1=Math.round(Math.abs(value1));value2=Math.round(Math.abs(value2))
    if (value1 !== 0)
      value1 = Math.log10(value1)

    if (value2 !== 0)
      value2 = Math.log10(value2)

    if (compareTo(value1, value2) === 0) {
      return -compareTo(avgCounts1, avgCounts2)
    }
    
    
    return (
      modifier *
      compareTo(value1, value2)
    );
  }
}

const dateFormatter = (date) => {
  if (!date || date === '-') {
    return "Unavailable"
  }
  return dayjs(date).format('MM/DD/YYYY HH:mm:ss[Z]')
}

export {
    countFormatterWithCommas,
    getPercentageSkippedOverTotal,
    commaValueComparator,
    multipurposeSortComparator,
    indexFormatter,
    graphSlopeComparator,
    dateFormatter
}