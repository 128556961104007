import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';

const InfoDisplayCard = ({ variant = 'h2', infoList = [] }) => {
    return (
        <Card sx={{ minWidth: 200, margin: '20px', maxWidth: 500 }} elevation={4}>
            <CardContent sx={{py:"10px"}}>
                {
                    infoList.map((item) => {
                        return (<Typography key={item.key} variant={variant} >{item.label} : <span style={{color: item.color}}>{item.value}</span></Typography>)
                    })
                }
                <Typography variant={'h5'} >{"All timestamps are in "}<span style={{color: "#006400"}}>{"UTC"}</span></Typography>
            </CardContent>
        </Card>
    );
}

export default InfoDisplayCard;
