import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import {  useSearchParams } from 'react-router-dom';
import { API } from '../../api/api_setup';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useAuth } from '../../hooks/useAuth';

const AuthCallback = () => {
  const [searchParams, ] = useSearchParams();
    const { login } = useAuth()
    const { showSnackbar } = useSnackbar()
    useEffect(()=> {
        API.get(`/user/auth/callback?code=${searchParams.get("code")}`)
        .then((resp)=>{
            login({
                token: resp.data.data.token,
                email: "sso_user"
              })
        })
        .catch((err) => {
            if (err.data && err.data.message)
                showSnackbar(err.data.message)
              else if (err.response && err.response.data && err.response.data.message)
                showSnackbar(err.response.data.message)
              else
                showSnackbar("Something went wrong !!")
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ height: 40, mt: 40 }}>
          <CircularProgress size={100}/>
      </Box>
      </Box>
    );
}

export default AuthCallback;
