import { List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import React from 'react';

const PodInfo = ({ podStatus, podData }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const getPodDataList = () => {
    if (!podData)
      return [{ "podName": "No info present" }]
    const output = []
    for (const podName in podData) {
      const obj = {
        podName,
        info: " "
      }
      for (const status in podData[podName]) {
        obj.info = obj.info + podData[podName][status] + " " + status + "/"
      }
      obj.info = obj.info.slice(0, -1)
      output.push(obj)
    }
    if (output.length === 0)
      output.push({ "podName": "No pods found" })
    return output
  }
  const handleClick = (event) => {
    if (podData)
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let color = 'black'
  if (podStatus === "Pending")
    color = "rgb(235, 91, 60)"
  else if (podStatus === "Running")
    color = "rgb(0, 179, 134)"

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Typography variant='body2' color={color} textAlign='center' marginTop={2}
      sx={{cursor: 'pointer'}}
      onClick={handleClick}
      >
      {podStatus}
    </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List dense>
          {getPodDataList().map(item => (
            <ListItem sx={{ py: '1px' }} key={item["podName"]}>
              <ListItemText
                sx={{ margin: '1px' }}
                primary={item["podName"]}
                secondary={item["info"]}
              />
            </ListItem>)
          )}
        </List>
      </Popover>
    </>
  );
}

export default PodInfo;
