import { Grid } from "@mui/material";

function CustomTabPanel(props) {
    const { children, value, index,sx, ...other } = props;
  
    return (
      <Grid item lg={12} md={12} sx={{ maxHeight: '70vh', minHeight: '20vh'}}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && {...children}}
      </Grid>
    );
  }

export default CustomTabPanel