import { Box, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { API } from '../../api/api_setup';
import DataDisplay from '../../components/DataDisplay';
import HomeIcon from '@mui/icons-material/Home';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useAuth } from '../../hooks/useAuth';
import {
  setDatasourcesOverview,
  setSortModelStructured,
  setSortModelUnstructured,
  setSortModelUnstructuredProcessedCount,
  setStructuredData,
  setUnstructuredData,
  setUnstructuredDataProcessedCount
} from './datasourceSlice';
import { getColumnsOverallStats, getColumnsStructured, getColumnsUnstructuredPendingCount, getColumnsUnstructuredProcessedCount } from '../../utils/constants';
import CustomTabPanel from '../../components/CustomTabPanel';
import DrawerMenu from '../../components/DrawerMenu';
import FileBreadCrumbs from '../../components/FileBreadCrumbs';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DsrcOverview = () => {

  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth()
  const { clusterName } = useParams()
  const [overallStats, setOverallStats] = useState([])
  const overview = useSelector(state => state.datasources.overview)
  const sortModelUnstructured = useSelector(state => state.datasources.sortModelUnstructured)
  const sortModelStructured = useSelector(state => state.datasources.sortModelStructured)
  const structuredData = useSelector(state => state.datasources.structuredData)
  const unstructuredData = useSelector(state => state.datasources.unstructuredData)
  const unstructuredDataProcessedCount = useSelector(state => state.datasources.unstructuredDataProcessedCount)
  const sortModelUnstructuredProcessedCount = useSelector(state => state.datasources.sortModelUnstructuredProcessedCount)
  const clusters = useSelector((state) => state.dashboard.clusters)
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const dispatch = useDispatch()
  const handleSortModelChangeUnstructured = (model, details) => {
    dispatch(setSortModelUnstructured(model))
  }

  const handleSortModelChangeStructured = (model, details) => {
    dispatch(setSortModelStructured(model))
  }

  const handleSortModelChangeUnstructuredProcessedCount = (model, details) => {
    dispatch(setSortModelUnstructuredProcessedCount(model))
  }

  useEffect(() => {
    const cluster = clusters.find(cluster => cluster.clusterName === clusterName)
    if (cluster)
      setOverallStats([cluster])

    if (overview[clusterName]) {
      dispatch(setUnstructuredData(overview[clusterName]["unstructuredPendingCountTrend"]))
      dispatch(setStructuredData(overview[clusterName]["structuredScanStatus"]))
      dispatch(setUnstructuredDataProcessedCount(overview[clusterName]["unstructuredProcessedCountTrend"]))
      setIsLoading(false)
      return
    }

    API.get(`/datasource/overview/${clusterName}`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
      .then(response => {
        dispatch(setDatasourcesOverview({ clusterName, data: response.data.data }))
        dispatch(setUnstructuredData(response.data.data["unstructuredPendingCountTrend"]))
        dispatch(setStructuredData(response.data.data["structuredScanStatus"]))
        dispatch(setUnstructuredDataProcessedCount(response.data.data["unstructuredProcessedCountTrend"]))
        setIsLoading(false)
      })
      .catch(err => {
        showSnackbar("Something went wrong !!")
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterName])


  const tabData = [
    {
      label: "Unstructured pending count trend",
      component:<DataDisplay
      rows={unstructuredData}
      columns={getColumnsUnstructuredPendingCount()}
      loading={isLoading}
      sortModel={sortModelUnstructured}
      onSortModelChange={handleSortModelChangeUnstructured}
    />
    },
    {
      label: "Unstructured processed count trend",
      component: <DataDisplay
      rows={unstructuredDataProcessedCount}
      columns={getColumnsUnstructuredProcessedCount()}
      loading={isLoading}
      sortModel={sortModelUnstructuredProcessedCount}
      onSortModelChange={handleSortModelChangeUnstructuredProcessedCount}
    />
    }
  ]


  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12}>
          <Navbar leftWidgetList={[
            <IconButton
              size="large"
              color="inherit"
              aria-label="home"
              onClick={() => { navigate("/") }}
              sx={{ marginLeft: '-10px' }}
              key={0}
            >
              <HomeIcon sx={{ fontSize: 40 }} />
            </IconButton>,
            <Typography variant='h4' sx={{ marginLeft: '10px' }} key={1}>{clusterName}</Typography>
          ]} 
          rightWidgetList={[<DrawerMenu />, <FileBreadCrumbs clusterName={clusterName}/>]}
          />
        </Grid>
        <Grid item lg={12} md={12} sx={{ padding: '15px' }}>
          <Typography variant='h5' color={'#313638'}>{"Overall stats"}</Typography>
        </Grid>
        <Grid item lg={12} md={12} height={160} borderBottom={"1px solid lightgrey"}>
          <DataDisplay
            isLoading={isLoading}
            rows={overallStats}
            disableFilter={true}
            disableSorting={true}
            columns={getColumnsOverallStats()}
          />
        </Grid>
        <Grid item lg={12} md={12} sx={{ padding: '15px' , marginTop: '10px'}}>
          <Typography variant='h5' color={'#313638'}>{"Unstructured datasources count trends"}</Typography>
        </Grid>
        <Grid item lg={12} md={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs"
              variant='scrollable'>
              {
                tabData.map((info, index) =>
                  <Tab
                    label={info.label}
                    {...a11yProps(index)}
                    key={info.label}
                  />)
              }
            </Tabs>
          </Box>
        </Grid>
        {
          tabData.map((info, index) => {
            let Component = info.component.type
            return (
              <React.Fragment key={info.label}>
                <CustomTabPanel value={tabValue} index={index}>
                  <Component {...info.component.props}/>
                </CustomTabPanel>
              </React.Fragment>
            )
          })
        }
        <Grid item lg={12} md={12} sx={{ padding: '15px',marginTop: '10px' }}>
          <Typography variant='h5' color={'#313638'}>{"Structured last scan status"}</Typography>
        </Grid>
        <Grid item lg={12} md={12} sx={{ maxHeight: '70vh', minHeight: '20vh' }}>
          <DataDisplay
            isLoading={isLoading}
            rows={structuredData}
            columns={getColumnsStructured()}
            sortModel={sortModelStructured}
            handleSortModelChange={handleSortModelChangeStructured}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DsrcOverview;
