import { configureStore } from '@reduxjs/toolkit'
import homeReducer from './pages/home/homeSlice'
import datasourceReducer from './pages/datasources/datasourceSlice'
import dashboardReducer from './pages/dashboard/dashboardSlice'
import clusterReducer from './pages/clusters/clusterSlice'

export default configureStore({
  reducer: {
    home: homeReducer,
    datasources: datasourceReducer,
    dashboard: dashboardReducer,
    cluster: clusterReducer
  },
})