import { createSlice } from '@reduxjs/toolkit'

export const datasourcesSlice = createSlice({
  name: 'datasources',
  initialState: {
    sortModelUnstructured: [{
      "field": "graphSlope",
      "sort": "asc"
    }],
    structuredData: [{
      "databasesWithoutConnectivity": "",
      "databasesWithoutPermissions": "",
      "datasourceId": "652ed118c4ba3409fffbaec3",
      "datasourceName": "MY SQL",
      "datasourceType": "MSSQL",
      "duration": "32 minutes ",
      "lastScanResult": "Failed",
      "lastSuccessfulScan": "NA",
      "nextScheduledScan": "NA",
      "podStatus": "Completed",
      "withoutConnectivity": "Yes",
      "withoutPermissions": "NA",
      "id":1
    }],
    unstructuredData: [{
      "datasourceId": "6501ed58d333bb03b92c7172",
      "datasourceName": "Datasource",
      "datasourceType": "GENERIC",
      "graphSlope": "",
      "pendingCountDay0": 0,
      "pendingCountDay1": 0,
      "pendingCountDay10": 0,
      "pendingCountDay11": 0,
      "pendingCountDay12": 0,
      "pendingCountDay13": 0,
      "pendingCountDay2": 0,
      "pendingCountDay3": 0,
      "pendingCountDay4": 0,
      "pendingCountDay5": 0,
      "pendingCountDay6": 0,
      "pendingCountDay7": 0,
      "pendingCountDay8": 0,
      "pendingCountDay9": 0,
      "id":1
    }],
    unstructuredDataProcessedCount: [{
      "datasourceId": "6501ed58d333bb03b92c7172",
      "datasourceName": "Datasource",
      "datasourceType": "GENERIC",
      "graphSlope": "",
      "processedCountDay0": 0,
      "processedCountDay1": 0,
      "processedCountDay10": 0,
      "processedCountDay11": 0,
      "processedCountDay12": 0,
      "processedCountDay13": 0,
      "processedCountDay2": 0,
      "processedCountDay3": 0,
      "processedCountDay4": 0,
      "processedCountDay5": 0,
      "processedCountDay6": 0,
      "processedCountDay7": 0,
      "processedCountDay8": 0,
      "processedCountDay9": 0,
      "id":1
    }],
    sortModelStructured: [],
    sortModelUnstructuredProcessedCount: [{
      "field": "graphSlope",
      "sort": "asc"
    }],
    overview: {},
  },
  reducers: {
    setSortModelUnstructured: (state, action) => {
      state.sortModelUnstructured = action.payload
    },
    setSortModelUnstructuredProcessedCount: (state, action) => {
      state.sortModelUnstructuredProcessedCount = action.payload
    },
    setSortModelStructured: (state, action) => {
      state.sortModelStructured = action.payload
    },
    setDatasourcesOverview: (state, action) => {
      state.overview[action.payload.clusterName] = action.payload.data
    },
    setStructuredData: (state, action) => {
      state.structuredData = action.payload
    },
    setUnstructuredData: (state, action) => {
      state.unstructuredData = action.payload
    },
    setUnstructuredDataProcessedCount: (state, action) => {
      state.unstructuredDataProcessedCount = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setSortModelStructured,
  setSortModelUnstructured,
  setDatasourcesOverview,
  setStructuredData,
  setUnstructuredData,
  setUnstructuredDataProcessedCount,
  setSortModelUnstructuredProcessedCount,
} = datasourcesSlice.actions

export default datasourcesSlice.reducer