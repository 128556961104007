import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useAuth } from '../../hooks/useAuth';
import { Box, CircularProgress, FormControlLabel, Grid, IconButton, Switch, Typography } from '@mui/material';
import Navbar from '../../components/Navbar';
import HomeIcon from '@mui/icons-material/Home';
import { API } from '../../api/api_setup';

const colorPalette = {
    'dark': {
     bg1: '#121212',
     bg2: '#3C3D37',
     text1: '#ECDFCC',
     text2: 'lightgrey'
    },
    'light': {
        bg1: undefined,
        bg2: undefined,
        text1: '#313638',
        text2: '#313638'
    }
}


const Crashlog = () => {
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar();
    const { user } = useAuth()
    const { clusterName } = useParams()
    const [crashlog, setCrashlog] = useState('')
    const [crashTime, setCrashTime] = useState('')
    const [darkModeChecked, setDarkModeChecked] = useState(false)
    const [palette, setPalette] = useState(colorPalette.light)
    const handleSwitchChange = (e) => {
        setDarkModeChecked(e.target.checked)
        if (e.target.checked) {
            setPalette(colorPalette.dark)
        } else {
            setPalette(colorPalette.light)
        }
    }
    useEffect(() => {
        API.get(`/crashlog/${clusterName}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then(response => {
                setCrashlog(response.data.data['raw'])
                setCrashTime(response.data.data['timestamp'])
                setIsLoading(false)
            })
            .catch(err => {
                showSnackbar("Something went wrong !!")
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clusterName])

    return (
        <Grid container>
            <Grid item lg={12} md={12}>
                <Navbar leftWidgetList={[
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="home"
                        onClick={() => { navigate("/") }}
                        sx={{ marginLeft: '-10px' }}
                        key={0}
                    >
                        <HomeIcon sx={{ fontSize: 40 }} />
                    </IconButton>,
                    <Typography variant='h4' sx={{ marginLeft: '10px' }} key={1}>{clusterName}</Typography>
                ]} />
            </Grid>
            <Grid item lg={12} md={12} sx={{ padding: '15px' , display: 'flex', justifyContent: 'space-between'}}
            bgcolor={palette.bg2} color={palette.text1}
            >
                <Typography variant='h5'>{`Crash Log recorded at time : ${crashTime}`}</Typography>
                <FormControlLabel 
                control={<Switch checked={darkModeChecked}
                onChange={handleSwitchChange}
                inputProps={{ 'aria-label': 'controlled' }}/>} 
                label="Dark mode" />
            </Grid>
            <Grid item lg={12} md={12} height={'85vh'} 
            bgcolor={palette.bg1}
            color={palette.text1}
            overflow={'scroll'}>
                {
                    isLoading ? <Box sx={{ height: 40, mt: 40, marginLeft: '45%' }}>
                        <CircularProgress size={100} />
                    </Box> : null
                }
                <Typography variant='body1' padding={2}>
                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                        {crashlog}
                    </pre>
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Crashlog;
