import { Typography } from "@mui/material"
import PodInfo from "../components/PodInfo"
import DatasourceNameCell from "../components/DatasourceNameCell"

const { Link } = require("react-router-dom")
const { countFormatterWithCommas, multipurposeSortComparator, getPercentageSkippedOverTotal, graphSlopeComparator, dateFormatter } = require("./helpers")
const dayjs = require("dayjs")

const renderCellDatasourceName = (cellValue) => {
  return <DatasourceNameCell datasourceId={cellValue.row["datasourceId"]} datasourceName={cellValue.value}/>
}

const graphSlopeColumn = {
  field: 'graphSlope',
  headerName: 'Graph slope coeffecient',
  width: 100,
  getSortComparator: graphSlopeComparator
}

const SAMPLE_DATASET_CLUSTERS = [
  {
    "clusterName": "Inspira",
    "id": 1,
    "overallPending": 0,
    "overallScanned": 0,
    "overallSkipped": 0,
    "scanSpeedDays1": -11355084,
    "scanSpeedDays5": 0.0
  },
  {
    "clusterName": "Snapfinance",
    "id": 2,
    "notReadyDatasources": null,
    "overallPending": 199290128,
    "overallScanned": 16577025,
    "overallSkipped": 8045534,
    "scanSpeedDays1": 21515,
    "scanSpeedDays5": 441768.2,
    "timestamp": "2024-08-22T00:01:59.293051089Z",
    "totalDatasourceConnected": 19
  },
  {
    "clusterName": "PlanetCourier",
    "id": 3,
    "notReadyDatasources": null,
    "overallPending": 35,
    "overallScanned": 278233,
    "overallSkipped": 206335,
    "scanSpeedDays1": 608,
    "scanSpeedDays5": 404.0,
    "timestamp": "2024-08-22T00:01:08.985212656Z",
    "totalDatasourceConnected": 9
}]

const HomePageColumns = [
  {
    field: 'id',
    headerName: 'Id',
    width: 50
  },
  {
    field: 'clusterName',
    headerName: 'Cluster name',
    width: 150,
    renderCell: (cellValue) => {
      return <Link to={`/dashboard/${cellValue.value}/datasources/overview`}>{cellValue.value}</Link>
    }
  },
  {
    field: 'totalDatasourceConnected',
    headerName: 'Connected Datasources',
    width: 150
  },
  {
    field: 'notReadyDatasources',
    headerName: 'Unready Datasources',
    width: 150
  },
  {
    field: 'overallPending',
    headerName: 'Overall Pending',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'overallScanned',
    headerName: 'Overall Scanned',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'overallSkipped',
    headerName: 'Overall Skipped',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'percentageSkipped',
    headerName: 'Skipped:Scanned ratio',
    width: 150,
    valueGetter: getPercentageSkippedOverTotal,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'processedCountDays1',
    headerName: 'Processed count 1 day average',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'processedCountDays5',
    headerName: 'Processed count 5 day average',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'pendingChangeDays1',
    headerName: 'Pending count daily change',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'clusterVersion',
    headerName: 'Cluster version',
    width: 150
  },
  {
    field: 'clusterUpdateTime',
    headerName: 'Cluster last update time',
    width: 150,
    valueFormatter: dateFormatter
  },
  {
    field: 'nodeInfo',
    headerName: 'Node info',
    valueGetter: (value, row) => {
      return `${row.nodeCount}-${row.nodeType}`
    }
  },
  {
    field: 'crashlogPresent',
    headerName: 'Crash log present',
    renderCell: (cellValue) => {
      
      if (cellValue.value.toLowerCase() === "yes")
      return <Link to={`/crashlog/${cellValue.row.clusterName}`}>{cellValue.value}</Link>
      else
      return cellValue.value
    }
  },
  {
    field: 'timestamp',
    headerName: 'Last dashboard update time',
    with: 200,
    valueFormatter: dateFormatter
  }
]

const overallStatsColumns = [];

const getColumnsUnstructuredPendingCount = () => {
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'datasourceName',
      headerName: 'Name',
      width: 200,
      renderCell: renderCellDatasourceName
    },
    {
      field: 'datasourceType',
      headerName: 'Type',
      width: 100
    },
    {
      field: 'podStatus',
      headerName: 'Status of consumer pod',
      width: 150,
      renderCell: (cellValue) => (<PodInfo podStatus={cellValue.value} podData={cellValue.row["podInfo"]}/>)
    },
  ]
  for (let i = 0; i < 14; i++) {
    let currentdate = dayjs(new Date())
    columns.push({
      field: `pendingCountDay${i}`,
      headerName: currentdate.subtract(i, 'days').format('MM/DD'),
      width: 150,
      valueFormatter: countFormatterWithCommas,
      renderCell: (cellValue) => {
        const currentCount = Number(cellValue.value)
        const prevCount = Number(cellValue.row[`pendingCountDay${i + 1}`])

        let color = "rgb(0, 179, 134)"
        let diff = currentCount - prevCount
        const copyDiff = diff
        if (diff >= 0) {
          color = "rgb(235, 91, 60)"
          diff = "+" + countFormatterWithCommas(diff)
        } else {
          diff = countFormatterWithCommas(diff)
        }

        let percentageChange = Math.round(Math.abs((copyDiff / prevCount) * 10000))
        percentageChange = percentageChange / 100
        if (!isFinite(percentageChange)) {
          percentageChange = "-%"
        } else {
          percentageChange = percentageChange + "%"
        }

        if (isNaN(currentCount) || isNaN(prevCount) || (prevCount === 0 && copyDiff === 0)) {
          return (<Typography variant='body2' textAlign='center' marginTop={2}>
            {countFormatterWithCommas(cellValue.value)}
          </Typography>)
        }

        return (
          <>
            <Typography variant='body2' textAlign='center' marginTop={'2px'}>
              {countFormatterWithCommas(cellValue.value)}
            </Typography>
            <Typography color={color} sx={{ marginTop: '1px', fontSize: '12px' }} textAlign='center'>
              {diff}({percentageChange})
            </Typography>
          </>
        )

      }
    })
  }
  columns.push(graphSlopeColumn)

  return columns
}

const getColumnsUnstructuredProcessedCount = () => {
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'datasourceName',
      headerName: 'Name',
      width: 200,
      renderCell: renderCellDatasourceName
    },
    {
      field: 'datasourceType',
      headerName: 'Type',
      width: 100
    },
    {
      field: 'podStatus',
      headerName: 'Status of consumer pod',
      width: 150,
      renderCell: (cellValue) => (<PodInfo podStatus={cellValue.value} podData={cellValue.row["podInfo"]}/>)
    },
  ]
  for (let i = 0; i < 14; i++) {
    let currentdate = dayjs(new Date())
    columns.push({
      field: `processedCountDay${i}`,
      headerName: currentdate.subtract(i, 'days').format('MM/DD'),
      width: 150,
      valueFormatter: countFormatterWithCommas,
      renderCell: (cellValue) => {
        const currentCount = Number(cellValue.value)
        const prevCount = Number(cellValue.row[`processedCountDay${i + 1}`])

        let color = "rgb(0, 179, 134)"
        let diff = currentCount - prevCount
        const copyDiff = diff
        if (diff <= 0) {
          color = "rgb(235, 91, 60)"
          diff = countFormatterWithCommas(diff)
        } else {
          diff = "+"+ countFormatterWithCommas(diff)
        }

        let percentageChange = Math.round(Math.abs((copyDiff / prevCount) * 10000))
        percentageChange = percentageChange / 100
        if (!isFinite(percentageChange)) {
          percentageChange = "-%"
        } else {
          percentageChange = percentageChange + "%"
        }

        if (isNaN(currentCount) || isNaN(prevCount) || (prevCount === 0 && copyDiff === 0)) {
          return (<Typography variant='body2' textAlign='center' marginTop={2}>
            {countFormatterWithCommas(cellValue.value)}
          </Typography>)
        }

        return (
          <>
            <Typography variant='body2' textAlign='center' marginTop={'2px'}>
              {countFormatterWithCommas(cellValue.value)}
            </Typography>
            <Typography color={color} sx={{ marginTop: '1px', fontSize: '12px' }} textAlign='center'>
              {diff}({percentageChange})
            </Typography>
          </>
        )
      }
    })
  }
  columns.push(graphSlopeColumn)
  return columns
}

const getColumnsStructured = () => {
  return [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'datasourceName',
      headerName: 'Name',
      width: 200,
      renderCell: renderCellDatasourceName
    },
    {
      field: 'datasourceType',
      headerName: 'Type',
      width: 100
    },
    {
      field: 'podStatus',
      headerName: 'Status of scanning pod',
      width: 150,
      renderCell: (cellValue) => (<PodInfo podStatus={cellValue.value} podData={cellValue.row["podInfo"]}/>)
    },
    {
      field: 'lastScanResult',
      headerName: 'Last scan result',
      width: 150,
      renderCell: (cellValue) => {
        let color = 'black'
        if (cellValue.value === "Failed")
          color = "rgb(235, 91, 60)"
        else if (cellValue.value === "Succeeded")
          color = "rgb(0, 179, 134)"

          return (<Typography variant='body2' color={color} textAlign='center' marginTop={2}>
            {cellValue.value}
          </Typography>)
      }
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 200
    },
    {
      field: 'lastSuccessfulScan',
      headerName: 'Last successful scan',
      width: 200
    },
    {
      field: 'nextScheduledScan',
      headerName: 'Next scheduled scan',
      width: 200
    },
    {
      field: 'withoutConnectivity',
      headerName: 'Without connectivity',
      width: 150,
      renderCell: (cellValue) => {
        let color = 'black'
        if (cellValue.value === "Yes")
          color = "rgb(235, 91, 60)"

          return (<Typography variant='body2' color={color} textAlign='center' marginTop={2}>
            {cellValue.value}
          </Typography>)
      }
    },
    {
      field: 'withoutPermissions',
      headerName: 'Without permissions',
      width: 150
    }
  ]
}

const getColumnsOverallStats = () => {
  if (overallStatsColumns.length === 0)
    overallStatsColumns.push(...HomePageColumns.filter((column) => (column.field !== "clusterName")))

  return overallStatsColumns
}

export {
  HomePageColumns,
  SAMPLE_DATASET_CLUSTERS,
  getColumnsStructured,
  getColumnsUnstructuredPendingCount,
  getColumnsUnstructuredProcessedCount,
  getColumnsOverallStats
}