import { Typography } from '@mui/material';
import React from 'react';

const DatasourceNameCell = ({ datasourceName, datasourceId }) => {
    return (
        <>
            <Typography variant='body2' marginTop={'2px'} sx={{ fontWeight: '600' }}>
                {datasourceName}
            </Typography>
            <Typography
                color={'darkblue'}
                sx={{
                    marginTop: '1px', fontSize: '12px'
                }}
            >
                {datasourceId}
            </Typography>
        </>
    )
}

export default DatasourceNameCell;
